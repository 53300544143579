@import "_admin_variables";
@import "../bootstrap/mixins";

// Reset and dependencies
@import "../bootstrap/normalize";
@import "../bootstrap/print";
@import "../bootstrap/glyphicons";

// Core CSS
@import "../bootstrap/scaffolding";
@import "../bootstrap/type";
@import "../bootstrap/code";
@import "../bootstrap/grid";
@import "../bootstrap/tables";
@import "../bootstrap/forms";
@import "../bootstrap/buttons";

// Components
@import "../bootstrap/component-animations";
@import "../bootstrap/dropdowns";
@import "../bootstrap/button-groups";
@import "../bootstrap/input-groups";
@import "../bootstrap/navs";
@import "../bootstrap/navbar";
@import "../bootstrap/breadcrumbs";
@import "../bootstrap/pagination";
@import "../bootstrap/pager";
@import "../bootstrap/labels";
@import "../bootstrap/badges";
@import "../bootstrap/jumbotron";
@import "../bootstrap/thumbnails";
@import "../bootstrap/alerts";
@import "../bootstrap/progress-bars";
@import "../bootstrap/media";
@import "../bootstrap/list-group";
@import "../bootstrap/panels";
@import "../bootstrap/responsive-embed";
@import "../bootstrap/wells";
@import "../bootstrap/close";

// Components w/ JavaScript
@import "../bootstrap/modals";
@import "../bootstrap/tooltip";
@import "../bootstrap/popovers";
@import "../bootstrap/carousel";

// Utility classes
@import "../bootstrap/utilities";
@import "../bootstrap/responsive-utilities";



/*
	//////////////////////////////////////////
	VARIABLES
	//////////////////////////////////////////
*/
$primary-font: Arial, Helvetica, Tahoma, Sans-serif;



/*
	//////////////////////////////////////////
	USER DEFINED STYLES
	//////////////////////////////////////////
*/

body, html {
	font-family: $primary-font;
}



.navbar-brand {
	img {
		height: 200%;
		margin-top: -10px;
		display: inline-block;
	}
}







input[type="checkbox"] {
	position: relative;
	top: 5px;
	height: 25px;
	width: 25px;
}




.has-error {
	label {
		color: $brand-danger !important;
	}

	input:not([type="range"]), select, textarea, .cropit-preview {
		color: black !important;
		border: 1px solid $brand-danger !important;
	}

	.help-block {
		color: $brand-danger !important;
	}
}

div.tagsinput {
	font-family: $primary-font !important;
	font-size: 1em !important;
	width: 100% !important;

	span.tag {
		font-family: $primary-font !important;
		font-size: 1em !important;
		border: none !important;
		border-radius: 0;
		padding: 8px 16px;
		background: $brand-warning;
		color: white;

		a {
			font-size: 1em;
			color: white;
		}
	}

	input {
		font-family: $primary-font !important;
		font-size: 1em !important;
		padding: 8px 8px;
		width: 150px !important;
	}
}





.cms-table {

	tr td:nth-last-child(-n+2) {
		width: 50px;
	}

	&.has-preview-images tr {

		height: 69px;

		td:first-child {
			width: 104px;
		}

		.cms-preview {
			position: absolute;
			background-size: cover;
			width: 50px;
			height: 50px;
			z-index: 10;

			transition: all .2s ease;

			&:hover {
				position: absolute;
				width: 150px;
				height: 150px;
				margin: -50px 0 0 0;//-50px;
				z-index: 20;
				
				transition: all .2s ease;
			}
		}
	}



	i {
		color: black;

		&:hover {
			opacity: 0.8;
		}
	}
	
	form button {
		background: transparent;
		border: none;

	}

}






.image-editor {

	margin-top: 15px;

	.cropit-preview {
		width: 300px;
		height: 300px;

		background-color: $gray-lighter;
		border: 1px solid $gray-light;

		float: left;

		.cropit-preview-background {
			opacity: .2;
		}

	}

	
	.cropit-image-input {
		position: absolute;
		visibility: hidden;
		z-index: -1;
	}

	
	.select-image-btn {
		float: left;
		margin: 0 0 0 30px;
	}

	.recrop-btn {
		margin: 0 0 0 30px;
	}


	
	input[type=range] {
		-webkit-appearance: none;
		width: 300px;
		margin: 30px 0 15px 0;
	}

	input[type=range]:focus {
		outline: none;
	}
	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 6px;
		cursor: pointer;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
		background: rgba(150, 150, 150, 0.26);
		border-radius: 0px;
		border: 0px solid rgba(0, 0, 0, 0);
	}
	input[type=range]::-webkit-slider-thumb {
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
		border: 0px solid rgba(0, 0, 0, 0);
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: #c8c8c8;
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -7px;
	}
	input[type=range]:focus::-webkit-slider-runnable-track {
		background: rgba(229, 229, 229, 0.26);
	}
	input[type=range]::-moz-range-track {
		width: 100%;
		height: 6px;
		cursor: pointer;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
		background: rgba(150, 150, 150, 0.26);
		border-radius: 0px;
		border: 0px solid rgba(0, 0, 0, 0);
	}
	input[type=range]::-moz-range-thumb {
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
		border: 0px solid rgba(0, 0, 0, 0);
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: #c8c8c8;
		cursor: pointer;
	}
	input[type=range]::-ms-track {
		width: 100%;
		height: 6px;
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		color: transparent;
	}
	input[type=range]::-ms-fill-lower {
		background: rgba(71, 71, 71, 0.26);
		border: 0px solid rgba(0, 0, 0, 0);
		border-radius: 0px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
	}
	input[type=range]::-ms-fill-upper {
		background: rgba(150, 150, 150, 0.26);
		border: 0px solid rgba(0, 0, 0, 0);
		border-radius: 0px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
	}
	input[type=range]::-ms-thumb {
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
		border: 0px solid rgba(0, 0, 0, 0);
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background: #c8c8c8;
		cursor: pointer;
		height: 6px;
	}
	input[type=range]:focus::-ms-fill-lower {
		background: rgba(150, 150, 150, 0.26);
	}
	input[type=range]:focus::-ms-fill-upper {
		background: rgba(229, 229, 229, 0.26);
	}



}










/*
	//////////////////////////////////////////
	MEDIA QUERIES
	//////////////////////////////////////////
*/

/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
	
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
	
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

}